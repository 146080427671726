import { Model } from 'common/helpers/models/model.abstract';
import { Store} from 'common/models/store.model';

export class User extends Model {
    store: Store = new Store();

    protected static includes: any = {
        'store.locales': Store
    }

    public constructor(user?: any){
        super();
        user = user || {};
        this.store = user.store || null;
    }

    public static getIncludes(...args: any): string {
        return User.transformIncludes(User.includes, ...args);
    }

    public static classIncludes(): any {
        return User.includes;
    }
}
