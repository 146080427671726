import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';
import { convertToFormData } from 'common/helpers/resources/convertToFormData';

@Injectable({ providedIn: 'root' })
export class LocalResource extends Resource {
    protected availableParams: Array<string> = [
        'include',
        'label',
        'search',
        '_method',
    ];
    protected availableUrlParams: Array<string> = [
        'id'
    ];
    // protected include = 'categories'; //TEMP: QUITAR INCLUDES DE RESOURCES, buscar en el modelo

    /**
     * Constructor
     *
     * @param {HttpClient} http
     */
    public constructor(protected http: HttpClient) {
        super("/locals");
    }

    /**
     * Get categories
     *
     * @return any
     */
    public GetLocal(id: any, _success?: any, _error?: any, include?: any): any {
        return this.Get(this.url('/:id'), {id: id, include: include}, _success, _error);
    }



    /**
     * Get category
     *
     * @return any
     */
    public GetLocales(_success?: any, _error?: any, include?: any): any {
        return this.Get(this.url(), {include: include}, _success, _error);
    }


    /**
     * Get categories
     *
     * @return any
     */
    public GetReport(date: any, _success?: any, _error?: any, include?: any): any {
        return this.Get(this.url('/get-report/'+date), {date: date}, _success, _error);
    }


    /**
     * Get categories
     *
     * @return any
     */
    public GetReportAdmin(id, date: any, _success?: any, _error?: any, include?: any): any {
        return this.Get(this.url('/get-report-admin/'+id+'/'+date), {date: date}, _success, _error);
    }


    /**
     *  edit
     *
     * @returns
     */
    public EditLocal(data: any, success?: any, error?: any, include?: any){
        return this.Post(this.url("/:id?_method=PATCH"), convertToFormData(data), {id: data.id}, success, error);
    }

     /**
     *  create
     *
     * @returns
     */
    public CreateLocal(data: any, success?: any, error?: any){
        return this.Post(this.url(), convertToFormData(data), null, success, error);
    }



    /**
     *  delete
     *
     * @returns
    */
    public DeleteLocal(id: any, success?: any, error?: any){
        return super.Delete(this.url("/:id"), {id: id}, success, error);
    }


    /**
     *  Restore
     *
     * @returns
     */
    public Restore(id: any, success?: any, error?: any){
        return this.Post(this.url("/restore/:id"), {}, {id: id, _method: 'PATCH'}, success, error);
    }
}
