import { Model } from 'common/helpers/models/model.abstract';
import { Local } from 'common/models/local.model';

export class Store extends Model {

 	id: number;
    phone: string;
    email: string;
    description: string;
    gallery:any = [];
    gallery_web:any = [];
    whatsapp: string;
    // photo: string;
    // photo_cover: string;
    locales:any = [];
    image_web:any = [];
    image_web_aboutus:any = [];

    protected static includes: any = {
        'locales': Local
        // 'products': Product
    }

    public constructor(store?: any){
		super();
        store = store || {};
        this.id = store.id || null;
        this.phone = store.phone || null;
        this.whatsapp = store.whatsapp || null;
        this.email = store.email || null;
        this.description = store.description || null;
        this.gallery = store.gallery || [];
        this.gallery_web = store.gallery_web || [];
        this.image_web = store.image_web || [];
        this.image_web_aboutus = store.image_web_aboutus || [];
        this.locales = store.locales ||  [];
    }

    public static getIncludes(...args: any): string {
        return Store.transformIncludes(Store.includes, ...args);
    }

    public static classIncludes(): any {
        return Store.includes;
    }
}
