import { Model } from 'common/helpers/models/model.abstract';
import { Category} from 'common/models/category.model';
import { Product } from 'common/models/product.model';

export class NewLocal extends Model {

 	id: number;
    name: string;
    type: string;
    email: string;
    email_login: string;
    conekta_key_private: string;
    conekta_key_public: string;
    password: string;

    protected static includes: any = {
        // 'categories': Category
        // 'products': Product
    }

    public constructor(newLocal?: any){
		super();
        newLocal = newLocal || {};
        this.id = newLocal.id || null;
        this.name = newLocal.name || null;
        this.email = newLocal.email || null;
        this.type = newLocal.type || null;
        this.conekta_key_private = null;
        this.conekta_key_public = newLocal.conekta_key_public || null;
        this.password = null;
        this.email_login = null;
    }

    public static getIncludes(...args: any): string {
        return NewLocal.transformIncludes(NewLocal.includes, ...args);
    }

    public static classIncludes(): any {
        return NewLocal.includes;
    }
}
