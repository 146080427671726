import { Model } from 'common/helpers/models/model.abstract';


export class Faq extends Model {
    id: number;
    question: string;
    answer:string;

    protected static includes: any = {
        // 'photo': Photo,
        // 'subcategories.photo': Category,
    };

    /**
     * Constructor
     *
     * @param category
     */
    public constructor(faq?: any) {
        super();
        faq = faq || {};
        this.id = faq.id || null;
        this.question = faq.question || '';
        this.answer = faq.answer || null;
    }

    public static getIncludes(...args: any): string {
        return Faq.transformIncludes(Faq.includes, ...args);
    }

    public static classIncludes(): any {
        return Faq.includes;
    }
}
