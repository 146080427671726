import { Model } from 'common/helpers/models/model.abstract';
import { Category} from 'common/models/category.model';
import { Product } from 'common/models/product.model';

export class Local extends Model {

 	id: number;
    name: string;
    type: string;
    photo: string;
    email: string;
    photo_cover: string;
    categories:any = [];
    deleted_at:any;

    protected static includes: any = {
        'categories': Category
        // 'products': Product
    }

    public constructor(local?: any){
		super();
        local = local || {};
        this.id = local.id || null;
        this.name = local.name || null;
        this.email = local.email || null;
        this.type = local.type || null;
        this.photo = local.photo || null;
        this.photo_cover = local.photo_cover || null;
        this.categories = local.categories ||  [];
        this.deleted_at = local.deleted_at || null;
    }

    public static getIncludes(...args: any): string {
        return Local.transformIncludes(Local.includes, ...args);
    }

    public static classIncludes(): any {
        return Local.includes;
    }
}
